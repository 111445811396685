import React, { useContext, useState } from "react";
import LoginModal from "../Modal/LoginModal";
import LocaleContext from "../LocaleContext";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { becomeAffiliate } from "../../actions/becomeAffiliateAction";
import { useLocation, useNavigate } from "react-router-dom";
import BecomeSupplierModal from "../Modal/BecomeSupplierModal";

const SupplierNewBanner = ({ supplierData, isLoading }) => {
  const { SetLoginKey, loginKey } = useContext(LocaleContext);
  const [ModalLogin, setModalLogin] = useState(false);
  const checklogin = useSelector((state) => state.login);
  const { t } = useTranslation();
  // Show Login Modal
  const showModal = (status) => {
    SetLoginKey(status);
    setModalLogin(true);
  };
  const closeLoginModal = () => {
    setModalLogin(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const profileReducer = useSelector((state) => state.profileReducer);
  const become_user = "Partner";
  const applyForSupplier = () => {
    Swal.fire({
      title: "Are your sure ?",
      text: "You will become an supplier user and your data as customer will be no longer available...!!!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(155 176 199)",
      cancelButtonColor: "rgb(227 46 46)",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.isConfirmed === true) {
        dispatch(becomeAffiliate(languageReducer, navigate, become_user));
      }
    });
  };

  console.log("profileReducer=", profileReducer?.user_type);

  const [showSupplierModal,setshowSupplierModal] = useState(false);

  return isLoading ? (
    <ShimmerSimpleGallery col="1" row="1" imageHeight={600} />
  ) : (
    <>
      <div className="new-banner-supplier">
        <div
          className="bg-new-image"
          style={{
            backgroundImage: `url(${supplierData?.suppliers_baner_image})`,
          }}
        >
          <div className="container">
            <div className="text-data-grid">
              <div className="div-blank"></div>
              <div className="text-data-new">
                <p>{supplierData?.suppliers_baner_title || "Banner Title"}</p>
                {checklogin === false ? (
                  <button
                    onClick={() => {
                      showModal("login");
                    }}
                    aria-label="Become a Supplier"
                  >
                    {t("_Become_a_Supplier")}
                  </button>
                ) : profileReducer?.user_type == "Customer" ? (
                  <button
                    aria-label="Become a Supplier"
                    // onClick={() => applyForSupplier()}
                    onClick={()=> setshowSupplierModal(true)}
                  >
                    {" "}
                    {t("_Become_a_Supplier")} 
                  </button>
                ) : profileReducer?.user_type == "Partner" ? (
                  <button aria-label={t("_already_a_supplier")}> 
                    {t("_already_a_supplier")} 
                  </button>
                ): profileReducer?.user_type == "Affiliate" ? (
                 ''
                ) : ( ''
                  // <button aria-label="Become a Supplier">
                  //   {" "}
                  //   {t("_Become_a_Supplier")}{" "}
                  // </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow_bottom_info"></div>
      <LoginModal
        loginInfo={loginKey}
        SetLoginKey={SetLoginKey}
        LoginModal={ModalLogin}
        LogincloseModal={closeLoginModal}
        userType={"Partner"}
      />
{showSupplierModal && 
<BecomeSupplierModal showSupplierModal ={showSupplierModal} setshowSupplierModal={setshowSupplierModal} />
}
    </>
  );
};

export default SupplierNewBanner;
