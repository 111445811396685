import React, { useState } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";
import customMarker from "../../assets/images/marker.svg"; // marker image

const MyGoogleMap = (props) => {
  console.log(props, "props");
  const { t } = useTranslation();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  let star = 0;

  return (
    <GoogleMap
      zoom={props.zoom}
      ref={props.onMapMounted}
      options={{
        disableDefaultUI: true,
        gestureHandling: "greedy",
        styles: [
          {
            featureType: "all",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
        ],
      }}
    >
      {props.places &&
        props.places.map((val, index) => (
          <React.Fragment key={index}>
            <Marker
              position={{ lat: val.latitude, lng: val.longitude }}
              onClick={() => handleMarkerClick(val)}
              icon={{ url: customMarker }}
            />
            {selectedMarker === val && (
              <InfoWindow
                position={{ lat: val.latitude, lng: val.longitude }}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <div className="row user-map-location mb-2">
                  <div
                    className={
                      star > 0
                        ? `col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 info-window-left-upper-div`
                        : "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 main-image-div-inner"
                    }
                    style={{
                      height:
                        val.review_description.length > 400 ? "auto" : "250px",
                    }}
                  >
                    <div className="main-top-map-div">
                      <div
                        className="location-main-img"
                        style={{ backgroundImage: `url(${val.image})` }}
                      >
                        <div className="prd_location_main mt-2">
                          <div className="icon_loc">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            <span className="location-name">
                              {val.location}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="prd_crd_loaction">
                        {/* <div className="prd_location_main mt-2">
                              <div className="icon_loc">
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                <span className="location-name">
                                  {val.location}
                                </span>
                              </div>
                            </div> */}
                        <div className="tour-name-and-date">
                          <div className="trip_details">
                            {val.activity_type}
                          </div>
                          <div className="">{val.date}</div>
                        </div>
                        {val?.title ? (
                          <div className="trip_detail_title">
                            {val?.title?.length >= 30
                              ? `${val?.title.substr(0, 50)}...`
                              : val?.title}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  {star > 0 && (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 location-images">
                      <h2>
                        <ReactStars
                          style={{ cursor: "pointer !important" }}
                          classNames="star-class"
                          size={16}
                          value={Number(2)}
                          {...reactRatings}
                        />
                      </h2>
                      <p
                        className="location-description"
                        dangerouslySetInnerHTML={{
                          __html: val.review_description,
                        }}
                      ></p>
                      {val.review_images.length > 0 && (
                        <div className="location-bottom-images">
                          {val.review_images.map((item, index) => (
                            <div
                              key={index}
                              className="extra-images"
                              style={{
                                backgroundImage: `url(${item})`,
                              }}
                            ></div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </InfoWindow>
            )}
          </React.Fragment>
        ))}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(MyGoogleMap));
