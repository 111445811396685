import { useContext, useEffect, useState } from "react";
import { MetaData, images } from "../actions/customFn";
import {
  AdventuresAwait,
  Banner,
  CategoriesNew,
  CulturalExperiences,
  CulturalTab,
  Destinations,
  ExperienceSlider,
  Faq,
  Footer,
  HomeNewBanner,
  LoadingBar,
  LoginModal,
  Partner,
  RecommendedTours,
  Testimonial,
  WhyChoose,
  SubscribeNewsletter
} from "../components";

import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import {
  asyncGetHomeData,
  asyncGetHomeMiddleData,
  asyncGetRecomendedTourData,
} from "../actions/pageAction";
import LocaleContext from "../components/LocaleContext";
import { HomeContext } from "../context";
import {
  getWishlistActivityList,
  removeFromWishlist,
} from "../actions/wishlistAction";
import { useClickAway } from "@uidotdev/usehooks";
import WishlistModel from "../components/Modal/WishlistModel";
import CreateNewListModel from "../components/Modal/CreateNewListModel";
import RotatingCircle from "../components/Loading/RotatingCircle";
import CategoryData from "../components/Home/CategoryData";
import { verifyUser } from "../actions/loginAction";
import { useHistory } from "react-router-use-history";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const history = useHistory();
  const {
    setHomeData, 
    setIsDataLoading,
    loginKey,
    SetLoginKey,
    ModalLogin,
    setModalLogin,
  } = useContext(LocaleContext);
  const homeData = useSelector((state) => state.home);
  const [isLoading, setIsLoading] = useState(true);
  const [homeMiddleData, setHomeMiddleData] = useState([]);
  const languageReducer = useSelector((state) => state.languageReducer);
  const checklogin = useSelector((state) => state.login);
  // const [loginKey, SetLoginKey] = useState("");
  // const [ModalLogin, setModalLogin] = useState(false);
  const [wishLishedLoader, setWishLishedLoader] = useState(false);
  const [wishLishedId, setWishLishedId] = useState("");
  const [wishLishedArray, setWishLishedArray] = useState([]);

  // ============search code=========
  const [searchParameter, setSearchParameter] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useClickAway(() => {
    setSearchList([]);
  });
  // ============search code=========

  const [activityList, setActivityList] = useState([]);
  const [wishlistParameter, setWishlistParameter] = useState("");
  const [openWishlistModel, setOpenWishlistModel] = useState(false);
  const [openNewListModel, setOpenNewListModel] = useState(false);
  const [searchListLoader, setSearchListLoder] = useState(false);
  const [slug, setSlug] = useState("recommended_tours");
  const [isCategoryLoading, setIsCategoryLoading] = useState(true);
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    let id = queryParams.get("id");
    if (id) {
      dispatch(verifyUser(id, navigate));
      queryParams.delete("id");
      history.push({ search: queryParams.toString() });
    }
  }, []);

  const closeWishlistModel = () => {
    setOpenWishlistModel(false);
  };
  const closeNewListModel = () => {
    setOpenNewListModel(false);
  };

  useEffect(() => {
    homeData && setIsLoading(false);
  }, [homeData]);

  useEffect(() => {
    if (languageReducer != "") {
      dispatch(
        asyncGetHomeData(
          languageReducer,
          setHomeData,
          navigate,
          setWishLishedLoader
        )
      );

      dispatch(
        asyncGetHomeMiddleData(
          languageReducer,
          setHomeMiddleData,
          navigate,
          setWishLishedLoader
        )
      );
    }
  }, [languageReducer, checklogin]);

  useEffect(() => {
    if (languageReducer != "") {
      setIsCategoryLoading(true);
      dispatch(
        asyncGetRecomendedTourData(
          languageReducer,
          navigate,
          slug,
          setIsCategoryLoading
        )
      );
    }
  }, [languageReducer, checklogin, slug]);

  const closeLoginModal = () => {
    setModalLogin(false);
  };

  const addWishList = (id, newId = "", country) => {
    if (checklogin) {
      setWishlistParameter({
        id: id,
        newId: newId,
        country: country,
      });

      if (newId != "") {
        if (wishLishedArray.includes(newId)) {
          dispatch(
            removeFromWishlist(
              id,
              newId,
              languageReducer,
              navigate,
              location,
              setWishLishedLoader,
              setWishLishedArray,
              wishLishedArray
            )
          );
        } else {
          // setOpenWishlistModel(true);
          dispatch(
            getWishlistActivityList(
              setActivityList,
              languageReducer,
              navigate,
              location,
              setOpenWishlistModel,
              setOpenNewListModel
            )
          );
        }
      }
    } else {
      setModalLogin(true);
      SetLoginKey("login");
    }
  };

  return (
    <LoadingOverlay active={isLoading === true} spinner={<LoadingBar />}>
      <HomeContext.Provider
        value={{
          // addToWishlist,
          wishLishedLoader,
          wishLishedId,
          wishLishedArray,
          setWishLishedArray,
          searchParameter,
          setSearchParameter,
          searchList,
          setSearchList,
          searchValue,
          setSearchValue,
          addWishList,
          searchRef,
          searchListLoader,
          setSearchListLoder,
          setSlug,
          slug,
          isCategoryLoading,
          setIsCategoryLoading,
        }}
      >
        {homeData && (
          <>
            <MetaData data={homeData.meta_data} />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 3 }}
            >
              <div className="home_main_page">
                {/* <Header /> */}

                {slug == "recommended_tours" ? (
                  <div className="home-dis">
                    <Banner />
                  </div>
                ) : (
                  <div className="banner_page_new">
                    <HomeNewBanner />
                  </div>
                )}

                <div className="new-list-catgory">
                  <CategoriesNew />
                </div>
                {slug == "recommended_tours" ? (
                  <RecommendedTours />
                ) : (
                  <CategoryData />
                )}
                <Destinations />
                <AdventuresAwait />
                <ExperienceSlider />
                <CulturalExperiences />
                {/* <WhyChoose /> */}
                <CulturalTab />
                {/* <Faq /> */}
                {/* <Testimonial />/ */}
                <Partner />
                {/* <SubscribeNewsletter /> */}
                <Footer />
              </div>
            </motion.div>
          </>
        )}
      </HomeContext.Provider>
      {/* <LoginModal
        // loginInfo={loginKey}
        // SetLoginKey={SetLoginKey}
        LoginModal={ModalLogin}
        LogincloseModal={closeLoginModal}
      /> */}

      {wishlistParameter && (
        <>
          <WishlistModel
            openWishlistModel={openWishlistModel}
            closeModel={closeWishlistModel}
            setOpenNewListModel={setOpenNewListModel}
            wishlistParameter={wishlistParameter}
            setWishLishedArray={setWishLishedArray}
            activityList={activityList}
            setIsWishlished={""}
            isWishlished={""}
          />
          <CreateNewListModel
            openNewListModel={openNewListModel}
            closeModel={closeNewListModel}
            wishlistParameter={wishlistParameter}
            setWishLishedArray={setWishLishedArray}
            setIsWishlished={""}
            isWishlished={""}
          />
        </>
      )}
    </LoadingOverlay>
  );
};

export default Home;
